.overlay {
  position: fixed;
  background-color: #E4E4E4;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1100;
  opacity: 0.5;
}

.AppBar {
  top: auto !important;
  padding-top: 8px;
  bottom: 0;
  background-color: #dbdbdb;
}

.Toolbar {
  min-height: auto !important;
}

.hr {
  border: none;
  border-top: 1px solid #c1baba;
  width: 95%;
}
